
const BY_MONEY = 'BY_MONEY';
const BY_ENERGY = 'BY_ENERGY';
const BY_DISTANCE = 'BY_DISTANCE';

export const ChargingSessionBillingType = {
    BY_MONEY, BY_ENERGY, BY_DISTANCE
}

const AVAILABLE = 'AVAILABLE';
const IN_USE = 'IN_USE';
const OUT_OF_SERVICE = 'OUT_OF_SERVICE';
const PLUGGED_IN_IDLE = 'PLUGGED_IN_IDLE';

export const ChargerStatus = {
    AVAILABLE, IN_USE, OUT_OF_SERVICE, PLUGGED_IN_IDLE
}

const ONLINE = 'ONLINE';
const OFFLINE = 'OFFLINE';

export const ConnectionStatus = {
    ONLINE, OFFLINE
}

const STANDARD = 'STANDARD';
const FREE = 'FREE';
const PROTECTED = 'PROTECTED';

export const ChargerMode = {
    STANDARD, FREE, PROTECTED
}

const INITIAL = 'INITIAL';
const AUTHORIZED = 'AUTHORIZED';
const CHARGING = 'CHARGING';
const NOT_CHARGING = 'NOT_CHARGING';
const CHARGING_FINISHED = 'CHARGING_FINISHED';
const COMPLETED = 'COMPLETED';
const ERROR = 'ERROR';
export const ChargingSessionStatus = {
        INITIAL,
        /** Authorized to charge, pre-billed */
        AUTHORIZED,
        /** Energy is flowing to the vehicle */
        CHARGING,
        /** Energy is not flowing to the vehicle either because it has been disconnected, or the vehicle is full */
        NOT_CHARGING,
        /** Energy is not flowing to the vehicle because the charging limit has been reached */
        CHARGING_FINISHED,
        /** End of session */
        COMPLETED,        
        /** Error state */
        ERROR
}
export const ChargingSessionFinishedStatus = {
    NOT_CHARGING,
    CHARGING_FINISHED,
    COMPLETED
}


const CREATED = 'CREATED';
const PROCESSING = 'PROCESSING';
const REQUIRES_ACTION = 'REQUIRES_ACTION';
const FAILED = 'FAILED';
const SUCCEEDED = 'SUCCEEDED';
const CANCELED = 'CANCELED';
const REQUIRES_CAPTURE = 'REQUIRES_CAPTURE';
const REFUNDED = 'REFUNDED';
export const PaymentStatus = {
		CREATED,        
		PROCESSING, 
		REQUIRES_ACTION,
		FAILED,
		SUCCEEDED,
		CANCELED,
		REQUIRES_CAPTURE, 
		REFUNDED
}

const STRIPE = "STRIPE";
const DLOCALGO = "DLOCALGO";

export const PaymentProcessor = {
    STRIPE, DLOCALGO
}
