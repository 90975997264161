import React from "react";
import { Loader } from 'react-loaders';
import 'loaders.css/loaders.min.css'
import { ThemeColors } from "../../helpers/ThemeColors";

const AppLoader = ({ message, color, style, className="app-loader" }) => (
    <div className={className}>
        <Loader 
            active
            type="line-scale" 
            color={color || ThemeColors().themeColor3}
            style={style}
            className="mb-2"
        />
        {message && (<span className="mt-4">{message}</span>)}
    </div>
);
export default AppLoader;
  
