import { defaultDirection } from '../constants/defaultValues';
import { BASE_URL, API_MEDIA_URL } from '../constants/services'
import moment from 'moment-timezone';
import parse from "html-react-parser";
import { ChargerStatus, ConnectionStatus } from '../constants/api';
import { ThemeColors } from './ThemeColors';

export const safeParse = (text) => {
  if (!text)
    return;
  return parse(text.replace(/\&quot;/g, '"'));
};

export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};


export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
  let direction = defaultDirection;
  if (localStorage.getItem('direction')) {
    const localValue = localStorage.getItem('direction');
    if (localValue === 'rtl' || localValue === 'ltr') {
      direction = localValue;
    }
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};

export const getSubdomain = () => {
  const parts = window.location.host.split(".");
  return parts[0];
}

export const getSetup = async () => {
  const subdomain = getSubdomain();

  if (localStorage.getItem(`setup-${subdomain}`)) {
    const localSetup = localStorage.getItem(`setup-${subdomain}`);
    return localSetup;
  } else {
    const response = await fetch(BASE_URL + '/api/v1/setup?domain=' + subdomain);

    const setup = response.json();
    if (setup.domain) {
      localStorage.setItem(`setup-${subdomain}`, setup);
    }
    return setup;
  }
};


export const getUserGuideUrl = () => {
  const subdomain = getSubdomain();
  return API_MEDIA_URL + "/manuals/" + subdomain + "/" + subdomain + ".pdf";
};


export const removeImgPrefix = (string) => {
  return string.split("base64,")[1];
};

export const getBase64 = (file, onOk, onError) => {
  if (!file) return;
  var img = null;
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    img = reader.result;
    if (img.startsWith("data:image")) {
      onOk(img);
    } else {
      onError('Invalid file format');
    }
  };
  reader.onerror = function (error) {
    onError('Error uploading file');
  };
}

export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  localStorage.setItem('direction', direction);
};

export const toLocalDate = (utcDate) => {
  const localDate = new Date(utcDate);
  const offset = localDate.getTimezoneOffset();
  return moment(localDate.getTime() + (offset * 60 * 1000));
}

export const timeout = async (ms) => {
  return new Promise(res => setTimeout(res, ms));
}

export const twoDigits = (number) => {
  let numberStr = number.toString();
  if (numberStr.length < 2) {
    number = '0' + number;
  }
  return number;
}

export const getLocalSetup = () => {
  const subdomain = getSubdomain();
  const setup = localStorage.getItem(`setup-${subdomain}`);
  return JSON.parse(setup);
}


export const isExpired = (expirationYear, expirationMonth) => {
  var exp = moment(expirationYear + "/" + expirationMonth, "YYYY/M");
  var today = moment();
  return today >= exp.add(1, 'months');
}

export const hasValidCreditCard = (user) => {
  var result = false;
  if (user && user.paymentMethods && user.paymentMethods.length > 0) {
    let i = 0;
    while (i < user.paymentMethods.length && !result) {
      let pm = user.paymentMethods[i];
      console.log("PM", pm);
      if (!isExpired(pm.expirationYear, pm.expirationMonth)) {
        result = true;
      }
      i++;
    }
    return result;
  }
}

export const isChargerAvailable = (station) => {
  if (!station)
    return false;

  if (station.connectivity != ConnectionStatus.ONLINE)
    return false;

  return true;

  //return (station.state == ChargerStatus.AVAILABLE || station.state == ChargerStatus.PLUGGED_IN_IDLE);
}


//Scenarios (internal representation)
const ALLOWED = 'ALLOWED';
const AUTH_REQUIRED = 'AUTH_REQUIRED';
const PAY_AS_YOU_GO = 'PAY_AS_YOU_GO';
const BUY_PACKAGE = 'BUY_PACKAGE';
const BUY_SPOT_PACKAGE = 'BUY_SPOT_PACKAGE';
const NOT_ALLOWED = 'NOT_ALLOWED';
const CONTACT_PM = 'CONTACT_PM';
const RESERVED = 'RESERVED';
const TO_RESERVE = 'TO_RESERVE';

export const ChargingScenario = {
  ALLOWED, // user can charge (paid, free, granted)
  AUTH_REQUIRED, // user needs to authenticate so we can check if he can charge
  PAY_AS_YOU_GO, // user has to pay for the charge (full price or discount)
  BUY_PACKAGE, // user has to buy a package 
  BUY_SPOT_PACKAGE, // user has to buy a package (outside reservation)
  NOT_ALLOWED, // user can't charge
  CONTACT_PM, // user has to contact PM in order to acquire service
  RESERVED, // charger is reserved
  TO_RESERVE // charger is reserved, but you can reserve
}

export const enumChargerStateToLabel = (state, connectivity="ONLINE") => {

  if (connectivity == "OFFLINE")
    return state == 'OUT_OF_SERVICE' ? 'charging.state.out-of-service' : 'charging.state.offline';

  switch (state) {
    case 'AVAILABLE':
      return 'charging.state.available';
    case 'IN_USE':
      return 'charging.state.in-use';
    case 'OUT_OF_SERVICE':
      return 'charging.state.out-of-service';
    case 'PLUGGED_IN_IDLE':
      return 'charging.state.plugged-in-idle';
    default:
      return 'general.no-state-found';
  }
}

export const chargerStateColor = (state, connectivity) => {
  if (connectivity == "OFFLINE"){
    return 'danger';
  }
  switch (state) {
    case 'AVAILABLE':
      return 'primary';
    case 'IN_USE':
      return 'secondary';
    case 'OUT_OF_SERVICE':
      return 'danger';
    case 'PLUGGED_IN_IDLE':
    default:
      return 'warning';
  }
}


export const packageTitle = (type) => {
  switch (type) {
    case 'SPOT_DAY':
      return 'pkg.title.spot-day';
    case 'FULL_STAY':
      return 'pkg.title.full-stay';
    case 'EXTENSION_STAY':
      return 'pkg.title.extension-stay';

  }
}

export const packageDescription = (type) => {
  switch (type) {
    case 'SPOT_DAY':
      return 'pkg.desc.spot-day';
    case 'FULL_STAY':
      return 'pkg.desc.full-stay';
    case 'EXTENSION_STAY':
      return 'pkg.desc.extension-stay';

  }
}

export const toCurrencyFormat = (value) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  })
  return formatter.format(value);
}

export const formatNumberDecimals = (number) => number.toLocaleString('en-US')

export const formatNumberNDecimals = (value, decimals) => {
  const formatter = new Intl.NumberFormat('en-US', {
    maximumSignificantDigits: decimals
  })
  return formatter.format(value);
}


export const enumPropertyStateToLabel = (state) => {
  switch (state) {
    case 'OCCUPIED':
      return 'properties.pages.occupied-state';
    case 'VACANT':
      return 'properties.pages.vacant-state';
    case 'READY':
      return 'properties.pages.ready-state';
    default:
      return 'general.no-state-found';
  }
}


export const getUnitFromMeter = (meter) => {
  switch (meter) {
    case "energy":
      return "kWh";
    case "temperature":
      return "\u00B0F";
    case "power":
      return "kW";
    case "voltage":
      return "V";
    case "current":
      return "A";
    case "powerFactor":
      return "%";
    case "frequency":
      return "Hz";
  }
  return "";
};


export const getLabelFromMeter = (meter, withUnit = true, messages, id) => {
  let label;
  let unit = getUnitFromMeter(meter);
  switch (meter) {
    case "energy":
      label = "charger.energy";
      break;
    case "temperature":
      label = "charger.temperature";
      break;
    case "power":
      label = "charger.power";
      break;
    case "voltage":
      label = "charger.voltage";
      break;
    case "current":
      label = "charger.current";
      break;
    case "powerFactor":
      label = "charger.power-factor";
      break;
    case "frequency":
      label = "charger.frequency";
      break;
  }
  return withUnit ? messages[label] + " (" + unit + ")" : messages[label];
};


export const getColorFromMeter = (meter) => {

  return ThemeColors().themeColor1;

  switch (meter) {
    case "energy":
    default:
      return ThemeColors().themeColor1;
    case "temperature":
      return ThemeColors().themeColor5;
    case "power":
      return ThemeColors().themeColor6;
    case "voltage":
      return ThemeColors().themeColor1;
    case "current":
      return ThemeColors().themeColor5;
    case "powerFactor":
      return ThemeColors().themeColor6;
    case "frequency":
      return ThemeColors().themeColor1;
  }
};

export const getBkgColorFromMeter = (meter) => {

  return ThemeColors().themeColor1_50;

  switch (meter) {
    case "energy":
    default:
      return ThemeColors().themeColor1_10;
    case "temperature":
      return ThemeColors().themeColor5_10;
    case "power":
      return ThemeColors().themeColor6_10;
    case "voltage":
      return ThemeColors().themeColor1_10;
    case "current":
      return ThemeColors().themeColor5_10;
    case "powerFactor":
      return ThemeColors().themeColor6_10;
    case "frequency":
      return ThemeColors().themeColor1_10;
  }
};

export const getChartForChargingSession = (meterValues, startTime, meterType, startKwh = 0, messages, id) => {
  let labels = [];
  let data = [];
  let prevKwh = startKwh;

  if (meterValues && meterValues.length > 0) {
    meterValues.forEach((mv, index) => {
      if (mv[meterType] != null && mv["power"] != null) {
        //labels.push(moment(mv.timestamp * 1000).format("h:mm a"));
        //labels.push(Math.round((mv.timestamp-startTime)/60)+" min");
        labels.push(moment.duration(mv.timestamp - startTime, 'seconds').humanize({ ss: 1, s: 60, m: 60, h: 48 }));
        if (meterType == "energy") {
          data.push((mv[meterType] - prevKwh).toLocaleString("en-US", { maximumFractionDigits: 2 }));
          prevKwh = mv[meterType];
        } else {
          data.push(mv[meterType]);
        }
      }
    });
  }

  return {
    labels: labels,
    datasets: [
      {
        label: getLabelFromMeter(meterType, true, messages, id),
        data: data,
        borderColor: getColorFromMeter(meterType),
        borderWidth: 2,
        pointBackgroundColor: ThemeColors().foregroundColor,
        pointBorderColor: getColorFromMeter(meterType),
        pointHoverBackgroundColor: getColorFromMeter(meterType),
        pointHoverBorderColor: ThemeColors().foregroundColor,
        pointRadius: 0,
        //pointBorderWidth: 2,
        pointHoverRadius: 4,
        fill: true,
        min: 0,
        backgroundColor: getBkgColorFromMeter(meterType),
      },
    ],
  };
};

export const meters = [
  "energy",
  "temperature",
  "power",
  "voltage",
  "current",
  "powerFactor",
  "frequency",
];

export const getDescFromMeter = (selectedMeter, messages) => {
  switch (selectedMeter) {
    case "energy":
    default:
      return messages["charger.meter.energy"];
    case "temperature":
      return messages["charger.meter.temperature"];
    case "power":
      return messages["charger.meter.power"];
    case "voltage":
      return messages["charger.meter.voltage"];
    case "current":
      return messages["charger.meter.current"];
    case "powerFactor":
      return messages["charger.meter.power-factor"];
    case "frequency":
      return messages["charger.meter.frequency"];
  }
};

export const enumChargingSessionSourceToSubLabel = (type) => {
  switch (type) {
    case 'PAYG_AUTH':
      return 'transaction.pages.source-payg.sub';
    case 'PAYG_NON_AUTH':
      return 'transaction.pages.source-payg-na.sub';
    case 'GRANTED_PAYG':
      return 'transaction.pages.source-granted-payg.sub';
    case 'RESERVATION_PACKAGE':
      return 'transaction.pages.source-reservation-package.sub';
    case 'PROPERTY_PACKAGE':
      return 'transaction.pages.source-property-package.sub';
    default:
      return null;
  }
}

export const chargingSessionSourceColor = (type) => {
  switch (type) {
    case 'FREE':
    case 'GRANTED_FREE':
      return 'primary';
    default:
      return 'secondary';
  }
}

export const getSessionDuration = (session) => {

  if (session.startTime && session.endTime) {
    return moment.duration(session.endTime - session.startTime, 'seconds').humanize({ ss: 1, s: 60, m: 60, h: 48 });
  }

  if (session.startTime) {
    return moment.duration(moment().diff(session.startTime, 'seconds')).humanize({ ss: 1, s: 60, m: 60, h: 48 });
  }

  return null;

}

export const getSessionEnergy = (session) => {

  if (session.state == "COMPLETED") {
    return formatNumberNDecimals(session.kwhConsumption,2);
  }

  return formatNumberNDecimals(session.kwhProgress,2);

}


export const enumChargingSessionSourceToLabel = (type) => {
  switch (type) {
    case 'PAYG_AUTH':
    case 'DRIVER':
      return 'transaction.pages.source-payg';
    case 'PAYG_NON_AUTH':
      return 'transaction.pages.source-payg-na';
    case 'RESERVATION':
      return 'transaction.pages.source-reservation';
    case 'FREE':
      return 'transaction.pages.source-free';
    case 'GRANTED_FREE':
    case 'GRANTED':
      return 'transaction.pages.source-granted-free';
    case 'GRANTED_PAYG':
      return 'transaction.pages.source-granted-payg';
    case 'RESERVATION_PACKAGE':
      return 'transaction.pages.source-reservation-package';
    case 'PROPERTY_PACKAGE':
      return 'transaction.pages.source-property-package';
    case 'OWNER':
      return 'transaction.pages.source-owner';
    default:
      return 'general.no-state-found';
  }
}

export const chargingSessionStateColor = (state) => {
  switch (state) {
    case 'COMPLETED':
    case 'CHARGING':
    case 'CHARGING_FINISHED':
      return 'primary';
    case 'AUTHORIZED':
    case 'INITIAL':
      return 'secondary';
    case 'ERROR':
      return 'danger';
    case 'NOT_CHARGING':
      return 'warning';
    default:
      return '';
  }
}

// returns the id text label used in the IntlMessages
export const enumChargingSessionStateToLabel = (state) => {
  switch (state) {
    case 'INITIAL':
      return 'transaction.pages.initial-state';
    case 'AUTHORIZED':
      return 'transaction.pages.authorized-state';
    case 'CHARGING':
      return 'transaction.pages.charging-state';
    case 'NOT_CHARGING':
      return 'transaction.pages.not-charging-state';
    case 'CHARGING_FINISHED':
      return 'transaction.pages.charging-finished-state';
    case 'COMPLETED':
      return 'transaction.pages.completed-state';
    case 'ERROR':
      return 'transaction.pages.error-state';
    default:
      return 'general.no-state-found';
  }
}

export const debounce = (func, wait) => {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			func.apply(context, args);
		};
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
	};
};


export const secondsToHourMin = (sec) => {
  let hours = Math.floor(sec / 3600);
  const minutes = Math.floor((sec - hours * 3600) / 60);
  let type = (hours < 12 || hours == 24) ? 'AM' : 'PM'
  hours = hours == 0 ? 12 : (hours <= 12 ? hours : hours - 12);
  return `${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes} ${type}`;
} 

export const getMobileOS = () => {
  const ua = navigator.userAgent
  if (/android/i.test(ua)) {
    return "Android"
  }
  else if ((/iPad|iPhone|iPod/.test(ua))
     || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
    return "iOS"
  }
  return "Other"
}

export const isSafariBrowser = () => 
    navigator.userAgent.indexOf("Safari") > -1 &&
    navigator.userAgent.indexOf("Chrome") <= -1 &&
    navigator.userAgent.indexOf("CriOS") <= -1
