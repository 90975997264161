import React, { Component, useState, useEffect } from "react";
import moment from "moment";

const AuthContext = React.createContext();

const emptyUser = {
  firstName: "",
  lastName: "",
  emailAddress: "",
  profilePictureUrl: null
}

const AuthProvider = (props) => {
  const [state, setState] = useState({
    token: localStorage.getItem("token"),
    account: localStorage.getItem("account_id"),
    user: localStorage.getItem("user") != null ? JSON.parse(localStorage.getItem("user")) : emptyUser,
  });

  const [doTour, setDoTour] = useState(false);

  //save user info in local storage
  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(state.user));
  }, [state.user]);

  const saveNewTourCompleted = (tour) => {
    // if has tour parse to list and push new tour
    if(localStorage.getItem("toursCompleted") != null) {
     let tours = JSON.parse(localStorage.getItem("toursCompleted"));
     if(tours.includes(tour)) return;
     tours.push(tour);
     localStorage.setItem("toursCompleted", JSON.stringify(tours));
    } else {
      // if doesn't have any tour, create new array with one tour
      let tours = [tour];
      localStorage.setItem("toursCompleted", JSON.stringify(tours));
    }
  }

  const getToursCompleted = () => {
    return localStorage.getItem("toursCompleted") != null ? JSON.parse(localStorage.getItem("toursCompleted")) : [];
  }

  const authFetch = async (input: RequestInfo, init?: RequestInit): Promise<Response> => {
    init = init || {};
    init.headers = {
      ...init.headers,
      "X-BH-OK2-AUTH-TOKEN": state.token,
    };
    if (init.body) {
      init.headers = {
        ...init.headers,
        "Content-Type": "application/json",
      }
    }
    return fetch(input, init).then((response) => {
      if (response.ok) {
        return response;
      } else {
        if (response.status == 401) {
          logout();
        }
        let error = new Error('Something went wrong');
        throw { ...error, response };
      }
    });
  };


  const login = async (data) => {
    var user = {};
    if (data.user.dateOfBirth) {
      var user = { ...data.user, dateOfBirth: moment(data.user.dateOfBirth).format("yyyy-MM-DD") };
    } else {
      user = { ...data.user }
    }

    if (data.user.addresses && data.user.addresses.length > 0) {
      user = { ...user, address: { ...data.user.addresses[0] } }
    }

    setState({
      token: data.token,
      account: data.user.account.id,
      user: user
    });
    localStorage.setItem("token", data.token); //for auth
    localStorage.setItem("account_id", data.user.account.id); //for requests
    console.log("User logged in");
  };

  const setUserProfile = (profile) => {
    setState({
      ...state,
      user: {
        ...state.user,
        ...profile
      }
    });
  }

  // change phone number user so that it's displayed in the edit profile immediately 
  const setPhoneValidated = (phone) => {
    setState({
      ...state,
      user: {
        ...state.user,
        phone: phone
      }
    })
  }

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("account_id");
    localStorage.removeItem("user");
    localStorage.removeItem("selected_property");
    localStorage.removeItem("toursCompleted");
    localStorage.removeItem("sessionId");
    console.log("User logged out");
  };

  const register = (data) => {
    setState({ ...state, ...data });
  };

  return (
    <AuthContext.Provider
      value={{ ...state, login, logout, authFetch, setUserProfile, setPhoneValidated, setDoTour,
       doTour, saveNewTourCompleted, getToursCompleted }} {...props}>
    </AuthContext.Provider>
  );
}

export { AuthProvider, AuthContext };