import React, { Component, useState, useEffect, useContext } from "react";
import { BASE_URL } from '../constants/services';
import { AuthContext } from '../context/AuthContext';

/**
 * App Context
 * 
 * In this context we manage application information that becomes available after
 * the Authentication of the user is successful.
 */
const AppContext = React.createContext();

const AppDataProvider = (props) => {

  const [activeChargingSessionId, setActiveChargingSessionId] = useState(localStorage.getItem('charging_session_id'));
  const [info, setInfo] = useState();

  const { account , authFetch} = useContext(AuthContext);
  const getInfo = async () => {
    if (!account)
      return;
    const response = await authFetch(
      BASE_URL +
        "/api/v1/accounts/" +
        account +
        "/chargingInfo"
    );

    if (response.ok){
      const data = await response.json();
      setInfo(data);
    }
  }

  useEffect(() => {
    getInfo();
  }, [account])


  useEffect(() => {
    if (activeChargingSessionId) {
      localStorage.setItem('charging_session_id', activeChargingSessionId)
    }
  }, [activeChargingSessionId])

  const unsetActiveChargingSessionId = () => {
    setActiveChargingSessionId(null);
    localStorage.removeItem('charging_session_id');
  }

  return (
    <AppContext.Provider
      value={{ activeChargingSessionId, setActiveChargingSessionId, unsetActiveChargingSessionId, getInfo, info }} {...props}>
    </AppContext.Provider>
  );
}

export { AppDataProvider, AppContext }