const data = [
  {
    id: 'charge',
    icon: 'iconsminds-flash-2', //icon: 'iconsminds-gas-pump' ?
    label: 'menu.charge',
    to: '/app/charge',
  },
  {
    id: 'properties',
    icon: 'iconsminds-home',
    label: 'menu.properties',
    to: '/app/properties',
  },
  {
    id: 'history',
    icon: 'iconsminds-clock-back',
    label: 'menu.history',
    to: '/app/history',
  },

  {
    id: 'account',
    icon: 'iconsminds-male',
    label: 'menu.account',
    to: '/app/account',
    subs: [
      {
        icon: 'iconsminds-male-2',
        label: 'menu.my-account',
        to: '/app/account',
      },
      {
        icon: 'iconsminds-car',
        label: 'menu.vehicles',
        to: '/app/vehicles',
      },
      {
        icon: 'iconsminds-credit-card',
        label: 'menu.my-cards',
        to: '/app/account/cards',
      },
      {
        icon: 'iconsminds-dollar',
        label: 'menu.my-payments',
        to: '/app/account/payments',
      },
    ],

  },
  /*{
    id: 'map',
    icon: 'iconsminds-location-2',
    label: 'menu.map',
    to: '/app/map',
  },*/
];

export default data;
