import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { configureStore } from './redux/store';
import { AuthProvider } from './context/AuthContext'
import { AppDataProvider } from './context/AppContext';
import AppLoader from './components/common/AppLoader.js';

const App = React.lazy(() => import(/* webpackChunkName: "App" */ './App'));

ReactDOM.render(
  <Provider store={configureStore()}>
  	<AuthProvider>
      <AppDataProvider>
        <Suspense fallback={<AppLoader />}>      
            <App />
        </Suspense>
      </AppDataProvider>
    </AuthProvider>
  </Provider>,
  document.getElementById('root')
);
/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.register();
